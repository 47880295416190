import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Argentina",
  "subtitle": "November 2014",
  "category": "South America"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Alfajor"
        }}>{`Alfajores`}</a>{` - One of my all time favorite cookies`}
        <ul parentName="li">
          <li parentName="ul">{`Havanna - They are famous for packaged alfajores but I'm not a fan. I prefer buying alfajores from the local bakeries!`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.arcor.com/en/brand/bon-o-bon"
        }}>{`Bon o Bon`}</a>{` - Chocolate bites with peanut butter!`}</li>
    </ul>
    <h2 {...{
      "id": "buenos-aires"
    }}>{`Buenos Aires`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <h4 {...{
      "id": "puertas-cerradas"
    }}>{`Puertas Cerradas`}</h4>
    <ul>
      <li parentName="ul">{`Closed-door restaurants & bars`}</li>
      <li parentName="ul">{`iLatina - Table was reserved for me all night`}</li>
    </ul>
    <h4 {...{
      "id": "argentina-has-very-deep-italian-ancestry"
    }}>{`Argentina has very deep Italian ancestry`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RJ9Mv2kYE3VWUZ9u5"
        }}>{`Pizzería Güerrin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://heladeriacadore.com.ar/"
        }}>{`Heladeria Cadore`}</a></li>
    </ul>
    <h4 {...{
      "id": "steak"
    }}>{`Steak`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.parrilladonjulio.com.ar/"
        }}>{`Parrilla Don Julio`}</a>{` - Best of the ones I tried`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lacabrera.com.ar/"
        }}>{`La Cabrera`}</a>{` - This was the most trendy, but my steak was so-so`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/pRZnUF9hX61gBZQSA"
        }}>{`The Recoleta Cemetery`}</a>{` - I rarely visit graveyards but this one was neat!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/2dq7iGbYZWRTh9YY9"
        }}>{`La Boca`}</a>{` - Area was cute but the police stopped to warn me to be cautious and to stay within the highlighted map suggested zone`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.malba.org.ar/"
        }}>{`MALBA`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/cvd5w1KD7shbHECA9"
        }}>{`San Telmo`}</a>{` - Sunday market that sells interesting stuff. Lively area with lots of food.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/xKfRxxdMXh4vpUjW7"
        }}>{`Palermo Soho & Hollywood`}</a>{` - Trendy shopping area`}</li>
      <li parentName="ul">{`Tango show - But I didn't like the one at "The Café Tortoni"`}</li>
    </ul>
    <h2 {...{
      "id": "el-calafate"
    }}>{`El Calafate`}</h2>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul">{`Small town with limited food options`}</li>
      <li parentName="ul">{`Viva La Pepa Creppes & Passion`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/lagunanimez.elcalafate/"
        }}>{`Laguna Nimez Reserve`}</a>{` - Short walk from the center of town. It was windy when I went but there were still lots of birds!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hieloyaventura.com/"
        }}>{`Hielo and Aventura`}</a>{` - This was my first ice trekking experience. Highly recommended! I did the “Mini Trek” which didn’t require a reservation in advance. Book the “Big Ice Trek,” at least a full day ahead. Don’t forget to try the glacier water! They awarded an alcoholic drink at the end of the trek.`}</li>
    </ul>
    <h2 {...{
      "id": "el-chaltén"
    }}>{`El Chaltén`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://elchalten.com/v4/en/visitor-information-centres-in-el-chalten.php"
        }}>{`Ceferino Fonzo Visitor Center`}</a>{` - Check park conditions; Their Rangers are very nice!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Gs1LUhpTZGeRqZzbA"
        }}>{`Loma del Pliegue Tumbado`}</a>{` - Was told this was like the "back side". Highly recommend if the clouds aren't blocking the view. This wasn't a popular trek when I went and this trail doesn't go in to the valley. The panoramic view at 1500m was gorgeous!!`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      